import type { Scale } from '@styled-system/core';

export const getTokenValue = (obj: Scale | undefined, key: string) => {
  // Return key if obj is undefined or an array
  if (!obj || Array.isArray(obj)) {
    return key;
  }

  // return object key value if it's a string or number, else return the key
  const value = obj[key];
  return typeof value === 'number' || typeof value === 'string' ? value : key;
};
