import { typographyScale } from '../typography-scale';

/**
 * Code ownership: Ragnar
 */

export interface RagnarTypography {
  ragnarHeadline1: string;
  ragnarHeadline2: string;
  ragnarHeadline3: string;
  ragnarHeadline4: string;
  ragnarHeadline5: string;
  ragnarBodyLg: string;
  ragnarBodyMd: string;
  ragnarBodySm: string;
  ragnarLabelMd: string;
  ragnarLabelSm: string;
  ragnarLabelXs: string;
  ragnarBodyXs: string;
  ragnarButtonLg: string;
  ragnarButtonMd: string;
  ragnarInputMd: string;
  ragnarInputSm: string;
  ragnarNumberLg: string;
  ragnarNumberMd: string;
  ragnarNumberSm: string;
}

export const ragnarTypography: RagnarTypography = {
  ragnarHeadline1: typographyScale.headline6Xl,
  ragnarHeadline2: typographyScale.headline3Xl,
  ragnarHeadline3: typographyScale.headline2Xl,
  ragnarHeadline4: typographyScale.headlineLg,
  ragnarHeadline5: typographyScale.headlineSm,
  ragnarBodyLg: 'text-112.5 leading-normal font-normal font-body',
  ragnarBodyMd: typographyScale.bodyMd,
  ragnarBodySm: typographyScale.bodySm,
  ragnarBodyXs: typographyScale.bodyXs,
  ragnarLabelMd: typographyScale.labelMd,
  ragnarLabelSm: typographyScale.labelSm,
  ragnarLabelXs: typographyScale.labelXs,
  ragnarButtonLg: typographyScale.buttonLg,
  ragnarButtonMd: typographyScale.buttonMd,
  ragnarInputMd: typographyScale.inputMd,
  ragnarInputSm: typographyScale.inputSm,
  ragnarNumberLg: typographyScale.numberLg,
  ragnarNumberMd: typographyScale.numberMd,
  ragnarNumberSm: typographyScale.numberSm,
};
