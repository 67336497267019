import * as React from 'react';
import { useClient } from '@splitsoftware/splitio-react';
import UAParser from 'ua-parser-js';

export const useSplitTrack = () => {
  const client = useClient();

  return React.useCallback(
    (eventType: string, value?: number, properties?: SplitIO.Properties) => {
      if (!client) {
        /* In case Split Provider is not rendered, which is only the case when we don't manage to store a voter identifier. */
        return;
      }
      const ua = new UAParser(navigator?.userAgent).getResult();
      const browserLanguage = navigator?.language?.toLowerCase();
      const browser = ua.browser.name ?? null;
      const os = ua.os.name ?? null;
      const device = ua.device.type || 'desktop';

      return client.track(eventType, value, {
        browserLanguage,
        browser,
        os,
        device,
        ...properties,
      });
    },
    [client],
  );
};
