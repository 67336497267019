import React from 'react';
import type { LabelAccessibilityT } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { styledTextRule, type TextT } from '../Text/Text';

export interface LinkT extends TextT, LabelAccessibilityT {
  href?: string | undefined;
  rel?: string | undefined;
  target?: string | undefined;
  underline?: boolean | undefined;
  truncate?: boolean;
  onClick?: React.MouseEventHandler<HTMLAnchorElement> | undefined;
}

export const FelaLink = styled('a', {
  displayName: 'Link',
})(
  styledTextRule,
  'color',
  'letterSpacing',
  'textAlign',
  'kosmosLineHeight',
  'kosmosSpacing',
  'kosmosFontSize',
  'maxWidth',
  'width',
  'display',
  'overflow',
  'flexbox',
  'position',
) as React.FC<LinkT>;

export const Link = React.forwardRef(
  (props: React.ComponentProps<typeof FelaLink>, ref) => {
    const { truncate, underline = true, className, ...rest } = props;

    const truncateClasses = [
      'text-ellipsis',
      'whitespace-nowrap',
      'overflow-hidden',
      'block',
      'max-w-full',
    ];

    const defaultClasses = [
      'r-link', // Reset selector
      underline ? 'underline' : 'no-underline',
      'text-primary',
      'font-body',
      'leading-normal',
      'break-words',
      'text-size-inherit',
      'rounded',
      'hover:opacity-60',
      'active:opacity-60',
      'cursor-pointer',
      'focus-visible:outline-4',
      'focus-visible:outline',
      'focus-visible:outline-interactive-focused',
      'focus-visible:outline-offset-2',
    ];

    if (truncate) defaultClasses.push(...truncateClasses);

    return (
      <FelaLink {...rest} ref={ref} className={cn(defaultClasses, className)} />
    );
  },
);
