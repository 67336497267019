import type { Regions, CoreClient } from './core/types';
import type { ClientBaseT } from './createClientCreator';
import { type TrackingClient } from './tracking';
import { type VotingClient } from './voting';

export interface HttpClients {
  voting: ClientBaseT<VotingClient>;
  core: ClientBaseT<CoreClient>;
  tracking: ClientBaseT<TrackingClient>;
}

interface ClientConfig<ClientName extends keyof HttpClients> {
  /** If no request specific region or baseUrl is given, the client will fall back to using this region. */
  defaultRegion: Regions;
  baseUrls?: Partial<Record<Regions, string>>;
  createClient(baseUrl: string): HttpClients[ClientName];
}

export interface HttpSetup {
  /** The name of the application you are setting up http clients in.
   * This is used for attaching metadata to error logging. */
  serviceName?: string;

  voting?: ClientConfig<'voting'>;
  core?: ClientConfig<'core'>;
  tracking?: ClientConfig<'tracking'>;
}

export const globalRoot = globalThis as any as {
  __mentimeterHttp: HttpSetup;
};

export type HttpClient = HttpClients[keyof HttpClients];

/** Cache clients keyed on region or base URL */
const clientCache = new Map<string, HttpClient>();

export function createGlobalClient<ClientName extends keyof HttpClients>(
  clientName: ClientName,
) {
  /** Creates HTTP clients lazily. Clients will only be created when they are used,
   * and new clients will be created if a new region or baseUrl is requested.
   */
  function getHttpClient(options?: {
    region?: Regions | null | undefined;
    baseUrl?: string | null | undefined;
  }) {
    if (options?.region && options?.baseUrl)
      throw new Error(
        'You cannot override region and baseUrl at the same time',
      );

    const clientSetup = globalRoot.__mentimeterHttp[clientName];

    if (!clientSetup)
      throw new Error(
        `HTTP client "${clientName}" does not exist. Please make sure that your application correctly sets this client up before accessing it.`,
      );

    let baseUrl: string | undefined;

    // Prio 1 is overrides from the options object on the request (baseUrl and region are mutually exclusive)
    if (options?.baseUrl) baseUrl = options?.baseUrl;
    else if (options?.region) baseUrl = clientSetup.baseUrls?.[options.region];
    // Prio 2 is the global fallback region.
    else if (clientSetup.defaultRegion)
      baseUrl = clientSetup.baseUrls?.[clientSetup.defaultRegion];

    if (!baseUrl)
      throw new Error(
        `Could not determine the baseUrl for HTTP client "${clientName}". Please make sure that your application correctly sets this client up before accessing it.`,
      );

    const cachedHttpClient = clientCache.get(baseUrl);
    if (cachedHttpClient) return cachedHttpClient as HttpClients[ClientName];

    const newHttpClient = clientSetup.createClient(baseUrl);
    if (!newHttpClient)
      throw new Error(
        `Could not set up HTTP client "${clientName}" with baseUrl ${baseUrl}`,
      );

    clientCache.set(baseUrl, newHttpClient);
    return newHttpClient as HttpClients[ClientName];
  }

  return getHttpClient;
}
