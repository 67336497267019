import type { Regions } from '@mentimeter/http-clients';

export const DEFAULT_REGION = 'eu';

const REGION_PATH: Record<Regions, string> = {
  eu: '',
  us: '/us',
};
export const getUrlPrefixByRegion = (region: Regions) =>
  REGION_PATH[region] || REGION_PATH['eu'];

export type RegionKey = 'al' | 'bl';
export const REGION_BY_KEY: Record<RegionKey, Regions> = {
  al: 'eu',
  bl: 'us',
};

const US_INITIALS = [9];
export const getRegionByVoteId = (id: string): Regions => {
  const isUsId = US_INITIALS.includes(Number(id[0]));
  if (isUsId) return REGION_BY_KEY.bl;
  return REGION_BY_KEY.al;
};

const getRegionByPrefix = (key: string): Regions => {
  const regionKey = key.substring(0, 2) as RegionKey;
  const region = REGION_BY_KEY[regionKey];
  if (region) {
    return region as Regions;
  }
  return 'eu';
};

export const getRegionByVoteKey = (key: string): Regions => {
  return getRegionByPrefix(key);
};

export const getRegionBySeriesId = (key: string): Regions => {
  return getRegionByPrefix(key);
};

export const getRegionByResetPasswordCode = (key: string): Regions => {
  return getRegionByPrefix(key);
};

export const getRegionByOneTimePassword = (
  oneTimePassword: string,
): Regions => {
  const isUsId = US_INITIALS.includes(Number(oneTimePassword[0]));
  if (isUsId) return REGION_BY_KEY.bl;
  return REGION_BY_KEY.al;
};

/**
 *
 * Prefixes the given key with "us" if supplied region is us.
 */
export const prefixUserIdWithUS = ({
  userId,
  region,
}: {
  userId: number | string;
  region: Regions;
}): string => {
  return region?.toLocaleLowerCase() === 'us'
    ? `us${userId}`
    : userId.toString();
};
