import { system } from '@styled-system/core';
import { getPadding } from './getPadding';

export const padding = system({
  p: {
    property: 'padding',
    scale: 'kosmosSpacing',
    transform: getPadding,
  },
  pt: {
    property: 'paddingTop',
    scale: 'kosmosSpacing',
    transform: getPadding,
  },
  pr: {
    property: 'paddingRight',
    scale: 'kosmosSpacing',
    transform: getPadding,
  },
  pb: {
    property: 'paddingBottom',
    scale: 'kosmosSpacing',
    transform: getPadding,
  },
  pl: {
    property: 'paddingLeft',
    scale: 'kosmosSpacing',
    transform: getPadding,
  },
  px: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'kosmosSpacing',
    transform: getPadding,
  },
  py: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'kosmosSpacing',
    transform: getPadding,
  },
});
