import {
  createRenderer as _createRenderer,
  prefixer,
  fallback,
} from '@mentimeter/ragnar-react';
import type { DSC } from '@mentimeter/ragnar-dsc';

interface CreateRendererOptions {
  dsc: DSC;
  selectorPrefix?: string;
  plugins?: (arg0: Array<any>) => Array<any>;
}

export function createRenderer({
  dsc,
  selectorPrefix = 'r-',
  plugins = (plugins) => plugins,
}: CreateRendererOptions) {
  return _createRenderer({
    selectorPrefix,
    mediaQueryOrder: dsc.breakpoints.map(
      (bp) => `screen and (min-width: ${bp})`,
    ),
    plugins: plugins([prefixer(), fallback()]),
  });
}

export const addUnit = (x?: number | string) => {
  if (typeof x === 'number') return `${x}px`; // is custom/dsc value that should add px unit
  return x; //is rem or hard coded value
};

const toPx = (x: number | string) => (typeof x === 'number' ? `${x}px` : x);

export const px = (x: number | string | Array<number | string>) =>
  Array.isArray(x) ? x.map<string>(toPx) : toPx(x);

export const stripAllNonNumericChars = (x: string): number =>
  parseInt(x.replace(/\D/g, ''), 10);
