import { fetcher, type IdentifierResponse } from './fetcher';
import { type Region, regions } from './map';
import {
  setIdentifierInStorage,
  removeIdentifierFromStorage,
  getIdentifierFromStorage,
} from './storage';

interface Config {
  canonicalUrl: string;
  region: Region;
}

interface OptionalIdentifier {
  identifier: string | null;
  jwt: string | null;
}

export class IdentifierClient {
  private canonicalUrl: Config['canonicalUrl'];
  private requests: Record<string, Promise<IdentifierResponse>> = {};
  private region: Region;
  constructor(config: Config) {
    this.region = config.region;
    this.canonicalUrl = config.canonicalUrl;
  }
  setRegion = (region: Region) => {
    this.region = region;
  };
  /**
   * Set the base urls for where identifiers should be fetched from.
   *
   * @param url - A string tied to a region or an object that maps all urls to a region.
   */
  public setBaseUrl = (url: string) => {
    this.canonicalUrl = url;
  };
  /**
   * Get an identifier object based on the region.
   * Will fetch automatically if the identifiers does not exist for the given region.
   *
   * @returns - A promise with an identifier object with a region tied to it.
   */
  public getIdentifier = async (): Promise<OptionalIdentifier> => {
    const region = this.region;
    const regionalIdentifier = this.getLocalIdentifier();
    const validIdentifier =
      regionalIdentifier?.identifier && regionalIdentifier?.jwt;

    if (validIdentifier) return regionalIdentifier;
    try {
      // Recycling pending promises
      const request = this.requests[region]
        ? this.requests[region]
        : (this.requests[region] = this.fetchIdentifier(region));

      const response = (await request) as IdentifierResponse;

      /**
       * The server response should set the cookie,
       * which is why we try to get the identifier here */
      const { identifier, jwt } = this.getLocalIdentifier();
      if ((!identifier || !jwt) && response.identifier && response.jwt) {
        setIdentifierInStorage('raw', response.identifier, response.region);
        setIdentifierInStorage('jwt', response.jwt, response.region);
      }

      return {
        identifier: getIdentifierFromStorage('raw', region) || null,
        jwt: getIdentifierFromStorage('jwt', region) || null,
      };
    } catch (error) {
      throw error;
    } finally {
      // Delete the finished promise when it has been set in the local storage
      delete this.requests[region];
    }
  };

  public clearIdentifier = () => {
    removeIdentifierFromStorage('jwt', this.region);
    removeIdentifierFromStorage('raw', this.region);
  };

  public clearAllIdentifiers = () => {
    regions.forEach((region) => {
      removeIdentifierFromStorage('jwt', region);
      removeIdentifierFromStorage('raw', region);
    });
  };

  /**
   * Fetch the region from the given `canonicalUrl`
   */
  private fetchIdentifier = async (
    region: Region,
  ): Promise<IdentifierResponse> =>
    fetcher(
      this.canonicalUrl,
      region,
      new Headers(),
      getIdentifierFromStorage('raw', region),
    );

  /**
   * Reads identifiers from the storage for each specified region.
   */
  public getLocalIdentifier: () => OptionalIdentifier = () => {
    const jwt = getIdentifierFromStorage('jwt', this.region) || null;
    const identifier = getIdentifierFromStorage('raw', this.region) || null;

    return { identifier, jwt };
  };
}
