import React from 'react';
import type { Border as BorderST, BoxShadow } from '@mentimeter/ragnar-dsc';
import { isThemeColor } from '@mentimeter/ragnar-dsc';
import { blackBase } from '@mentimeter/ragnar-colors';
import type { BorderStyleProperty, Rule } from '@mentimeter/ragnar-styled';
import { styled } from '@mentimeter/ragnar-styled';
import type { ComponentProps } from 'react';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { px } from '../utils';

export interface BoxRuleInput {
  backgroundImage?: string | undefined;
  backgroundPosition?: string | undefined;
  backgroundSize?: string | undefined;
  backgroundAttachment?: string | undefined;
  borderColor?: string | undefined;
  borderStyle?: BorderStyleProperty | undefined;
  borderWidth?: BorderST | undefined;
  borderTopWidth?: BorderST | undefined;
  borderRightWidth?: BorderST | undefined;
  borderBottomWidth?: BorderST | undefined;
  borderLeftWidth?: BorderST | undefined;
  boxShadow?: keyof BoxShadow | undefined;
}

export const rule: Rule<BoxRuleInput> = ({
  backgroundImage,
  backgroundPosition,
  backgroundSize,
  backgroundAttachment,
  borderColor,
  borderStyle,
  borderWidth,
  borderTopWidth,
  borderRightWidth,
  borderBottomWidth,
  borderLeftWidth,
  boxShadow,
  theme,
}) => ({
  backgroundImage,
  backgroundPosition,
  backgroundSize:
    backgroundSize !== undefined
      ? backgroundSize
      : backgroundImage !== undefined
        ? 'cover'
        : undefined,
  backgroundAttachment,
  borderStyle,
  borderColor:
    borderColor !== undefined && isThemeColor(theme, borderColor)
      ? theme.colors[borderColor]
      : borderColor,
  borderWidth: px(
    borderWidth !== undefined && typeof borderWidth === 'number'
      ? theme.borders[borderWidth] || borderWidth
      : borderWidth,
  ),
  borderTopWidth: px(
    borderTopWidth !== undefined && typeof borderTopWidth === 'number'
      ? theme.borders[borderTopWidth] || borderTopWidth
      : borderTopWidth,
  ),
  borderRightWidth: px(
    borderRightWidth !== undefined && typeof borderRightWidth === 'number'
      ? theme.borders[borderRightWidth] || borderRightWidth
      : borderRightWidth,
  ),
  borderBottomWidth: px(
    borderBottomWidth !== undefined && typeof borderBottomWidth === 'number'
      ? theme.borders[borderBottomWidth] || borderBottomWidth
      : borderBottomWidth,
  ),
  borderLeftWidth: px(
    borderLeftWidth !== undefined && typeof borderLeftWidth === 'number'
      ? theme.borders[borderLeftWidth] || borderLeftWidth
      : borderLeftWidth,
  ),
  boxShadow:
    boxShadow !== undefined && typeof boxShadow === 'string'
      ? `${theme.boxShadow[boxShadow]} ${blackBase}`
      : undefined,
});

const FelaBox = styled('div')(
  rule,
  'color',
  'layout',
  'flexbox',
  'position',
  'kosmosSpacing',
  'kosmosGrid',
  'kosmosBorderRadius',
);

export type BoxT = ComponentProps<typeof FelaBox>;

export const Box = React.forwardRef((props: BoxT, ref) => {
  const { className, ...rest } = props;

  const defaultClasses = [
    'r-box',
    'flex',
    'flex-col',
    'flex-none',
    'items-start',
    'min-w-0',
    'min-h-0',
    'relative',
    'w-auto',
  ];

  return (
    <FelaBox {...rest} ref={ref} className={cn(defaultClasses, className)} />
  );
});
