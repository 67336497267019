import type { Feature } from './features';

export interface MentiErrorOptions extends ErrorOptions {
  feature: Feature;
  cause?: unknown;
  /**
   * Tags are key/value string pairs that are both indexed and searchable.
   * Tags power features in sentry.io such as filters and tag-distribution maps.
   * Tags also help you quickly both access related events and view the tag distribution for a set of events.
   * Common uses for tags include hostname, platform version, and user language.
   */
  tags?: Record<string, string | number | boolean>;
}

export class MentiError extends Error {
  feature: Feature;
  tags?: Record<string, string | number | boolean>;

  constructor(errorMessage: string, options: MentiErrorOptions) {
    super(errorMessage, options);

    if (options.tags) {
      this.tags = options.tags;
    }

    this.feature = options.feature;
  }
}
