import type { Scale } from '@styled-system/core';
import { getKeyStartsWithNegative } from './getKeyStartsWithNegative';

const isKosmosToken = (n: string, scale: Scale | undefined): boolean => {
  if (typeof n !== 'string') return false;
  if (!scale) return false;

  const [key] = getKeyStartsWithNegative(n);
  return Object.keys(scale).includes(key);
};

export { isKosmosToken };
