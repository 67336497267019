import { type Scale } from '@styled-system/core';
import { px } from '../utilites';
import {
  getThemeFromPropsArray,
  isKosmosToken,
  getKeyStartsWithNegative,
  getTokenValue,
} from '../utils';
import { getMargin as getRagnarMargin } from '../../';

export function getMargin(
  n: string,
  scale?: Scale,
  ...props: any
): string | undefined {
  if (n === '' || !scale) return;

  if (!isKosmosToken(n, scale)) {
    const theme = getThemeFromPropsArray(props);
    return getRagnarMargin(n, theme.space);
  }

  const [key, startsWithNegative] = getKeyStartsWithNegative(n);
  const value = getTokenValue(scale, key);
  return startsWithNegative ? '-' + px(value) : px(value);
}
