import { useRef, useEffect } from 'react';

/** A hook that remembers the value from the previous render cycle of whatever you send in.
 *
 * **NOTE:** The value will be undefined on the first render, unless you add a default value.
 * @param value The value to keep track of
 */
export function usePrevious<T>(value: T): T | undefined;

/** A hook that remembers the value from the previous render cycle of whatever you send in.
 *
 * @param value The value to keep track of
 * @param defaultValue The fallback value for the first render
 */
export function usePrevious<T, U extends T>(value: T, defaultValue: U): T;

export function usePrevious<T, U extends T>(
  value: T,
  defaultValue?: U,
): T | undefined {
  const ref = useRef<T | undefined>(defaultValue);

  // Store current value in ref
  useEffect(() => {
    ref.current = value;
  }, [value]);

  // Return previous value (happens before update in useEffect above)
  return ref.current;
}
