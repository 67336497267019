import { sendMetric } from '@mentimeter/errors/sendMetric';
import { AxiosError } from 'axios';
import { isServer } from '@mentimeter/detect-server';
import { globalRoot } from './global-http-internal';

declare global {
  interface Window {
    next?: any;
  }
}

export const wasHandledNetworkError = (error: Error | AxiosError) => {
  if (error instanceof AxiosError && error.response) {
    if (error.response.status > 399) {
      return true;
    }
  }
  return false;
};

export const wasMissingResponseError = (error: Error | AxiosError) => {
  return error instanceof AxiosError && error.request && !error.response;
};

/**
 * Logic inspired by axios error handling docs: https://axios-http.com/docs/handling_errors
 */
export const handleNetworkError = (error: AxiosError) => {
  if (isServer) {
    return Promise.reject(error);
  }
  if (error.response) {
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx

    // Send 3xx's straight on
    if (error.response.status < 399) {
      return Promise.reject(error);
    }

    // We don't want client-metric errors to cause new client-metrics
    if (error.config?.url && error.config?.url === '/client-metrics') {
      return Promise.reject(error);
    }

    // We rename the message to differentiate between other axios errors in sentry
    error.message = `Mentimeter http network error caught with status: ${error.response.status}`;
    return Promise.reject(error);
  } else if (error.request) {
    // The request was made but no response was received
    sendMetric({
      name: `mmjs.request.noresponse`,
      value: 1,
      tags: [
        `zone:${window.location.host}`,
        `service:${globalRoot.__mentimeterHttp?.serviceName ?? 'undefined'}`,
      ],
    });
    // We rename the message to differentiate between other axios errors in sentry
    error.message =
      'Mentimeter http network error caught with no server response';
    return Promise.reject(error);
  } else {
    // Something happened in setting up the request that triggered an Error,
    // these should be handled by Sentry
    return Promise.reject(error);
  }
};
