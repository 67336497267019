import { system } from '@styled-system/core';
import { getGap } from './getGap';

export const grid = system({
  grid: true,
  gridAutoColumns: true,
  gridAutoFlow: true,
  gridAutoRows: true,
  gridArea: true,
  gridColumnStart: true,
  gridColumnEnd: true,
  gridRowStart: true,
  gridRowEnd: true,
  gridTemplate: true,
  gridTemplateArea: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  justifySelf: true,
  alignSelf: true,
  placeSelf: true,
  gap: {
    properties: ['gap'],
    scale: 'kosmosSpacing',
    transform: getGap,
  },
  columnGap: {
    properties: ['columnGap'],
    scale: 'kosmosSpacing',
    transform: getGap,
  },
  rowGap: {
    properties: ['rowGap'],
    scale: 'kosmosSpacing',
    transform: getGap,
  },
});
