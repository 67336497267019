import Cookies, { cookiesAreEnabled } from '@mentimeter/cookies';
import { LocalStorage } from '@mentimeter/storage';
import { identifierKeysMap, type Region, type IdentifierTypes } from './map';

const INFINITY = new Date('Fri, 31 Dec 9999 23:59:59 GMT');

export const cache = new Map<string, string>();

export const storageIsAvailable = () =>
  LocalStorage.isSupported() || cookiesAreEnabled();

export const setIdentifierInStorage = (
  type: IdentifierTypes,
  identifier: string,
  region: Region = 'eu',
) => {
  const key = identifierKeysMap[type][region];
  if (storageIsAvailable()) {
    Cookies.set({
      name: key,
      value: identifier,
      type: 'necessary',
      options: {
        expires: INFINITY,
        sameSite: 'None',
      },
    });
    LocalStorage.setItem({ key, value: identifier, type: 'necessary' });
  } else {
    cache.set(key, identifier);
  }
};

export const removeIdentifierFromStorage = (
  type: IdentifierTypes,
  region: Region = 'eu',
): void => {
  const key = identifierKeysMap[type][region];

  if (storageIsAvailable()) {
    // Cookie can be set on the backend where domain is set differently:
    // 1. .www.menti.com
    // 2. www.menti.com
    // This means that we need to try to remove it in two different ways.
    Cookies.remove(key, { domain: window.location.hostname });
    Cookies.remove(key);
    LocalStorage.removeItem(key);
  } else {
    cache.delete(key);
  }
};

export const getIdentifierFromStorage = (
  type: IdentifierTypes,
  region: Region = 'eu',
): string | null | undefined => {
  const key = identifierKeysMap[type][region];
  if (storageIsAvailable()) {
    return Cookies.get(key) || LocalStorage.getItem(key);
  } else {
    return cache.get(key);
  }
};
