import type { styleFn } from '@styled-system/core';
import { system, get, compose } from '@styled-system/core';
import { default as _color } from '@styled-system/color';
import {
  kosmosSpacing,
  kosmosBorderRadius,
  kosmosGrid,
  kosmosFontSize,
  kosmosLineHeight,
} from './kosmos';

const color = _color as styleFn;
export { compose, color };

type Unit = string | number;
type Scale = any;

const isNumber = (n: Unit): n is number => typeof n === 'number' && !isNaN(n);
const px = (n: Unit): string => (isNumber(n) ? n + 'px' : n);

function getWidth(n: Unit, scale: Scale) {
  const value = get(scale, n, !isNumber(n) || n > 1 ? n : n * 100 + '%');
  return px(value);
}

function getHeight(n: Unit, scale: Scale): string {
  const value = get(scale, n, n);
  return px(value);
}

export function getMargin(n: Unit, scale: Scale): string {
  if (!isNumber(n)) {
    return px(get(scale, n, n));
  }

  const isNegative = n < 0;
  const absolute = Math.abs(n);
  const value = get(scale, absolute, absolute);
  if (!isNumber(value)) {
    return isNegative ? '-' + value : value;
  }
  return px(value * (isNegative ? -1 : 1));
}

export function getPadding(n: Unit, scale: Scale): string {
  if (!isNumber(n)) {
    const value = get(scale, n, n);
    return px(value);
  }

  const absolute = Math.abs(n);
  const value = get(scale, absolute, absolute);
  if (!isNumber(value)) {
    return value;
  }
  return px(value);
}

export function getFontSize(n: Unit, scale: Scale): string {
  const value = get(scale, n, n);
  return px(value);
}

const fontSize = system({
  fontSize: {
    property: 'fontSize',
    scale: 'fontSizes',
    transform: getFontSize,
  },
});

const lineHeight = system({
  lineHeight: {
    property: 'lineHeight',
    scale: 'lineHeights',
  },
});

const letterSpacing = system({
  letterSpacing: {
    property: 'letterSpacing',
    scale: 'letterSpacings',
  },
});

const textAlign = system({
  textAlign: true,
});

const margin = system({
  m: {
    property: 'margin',
    scale: 'space',
    transform: getMargin,
  },
  mt: {
    property: 'marginTop',
    scale: 'space',
    transform: getMargin,
  },
  mr: {
    property: 'marginRight',
    scale: 'space',
    transform: getMargin,
  },
  mb: {
    property: 'marginBottom',
    scale: 'space',
    transform: getMargin,
  },
  ml: {
    property: 'marginLeft',
    scale: 'space',
    transform: getMargin,
  },
  mx: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'space',
    transform: getMargin,
  },
  my: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'space',
    transform: getMargin,
  },
});

const padding = system({
  p: {
    property: 'padding',
    scale: 'space',
    transform: getPadding,
  },
  pt: {
    property: 'paddingTop',
    scale: 'space',
    transform: getPadding,
  },
  pr: {
    property: 'paddingRight',
    scale: 'space',
    transform: getPadding,
  },
  pb: {
    property: 'paddingBottom',
    scale: 'space',
    transform: getPadding,
  },
  pl: {
    property: 'paddingLeft',
    scale: 'space',
    transform: getPadding,
  },
  px: {
    properties: ['paddingLeft', 'paddingRight'],
    scale: 'space',
    transform: getPadding,
  },
  py: {
    properties: ['paddingTop', 'paddingBottom'],
    scale: 'space',
    transform: getPadding,
  },
});

const space = compose(margin, padding);

const overflow = system({ overflow: true });
const display = system({ display: true });

const flexbox = system({
  alignItems: true,
  alignContent: true,
  justifyItems: true,
  justifyContent: true,
  flexWrap: true,
  flexDirection: true,
  flex: true,
});

const grid = system({
  grid: true,
  gridAutoColumns: true,
  gridAutoFlow: true,
  gridAutoRows: true,
  gridArea: true,
  gridColumnStart: true,
  gridColumnEnd: true,
  gridRowStart: true,
  gridRowEnd: true,
  gridTemplate: true,
  gridTemplateArea: true,
  gridTemplateColumns: true,
  gridTemplateRows: true,
  justifySelf: true,
  alignSelf: true,
  placeSelf: true,
  gap: {
    properties: ['gap'],
    scale: 'space',
    transform: getPadding,
  },
  columnGap: {
    properties: ['columnGap'],
    scale: 'space',
    transform: getPadding,
  },
  rowGap: {
    properties: ['rowGap'],
    scale: 'space',
    transform: getPadding,
  },
});

const height = system({
  height: {
    property: 'height',
    scale: 'heights',
    transform: getHeight,
  },
});

const minHeight = system({
  minHeight: {
    property: 'minHeight',
    transform: px,
  },
});

const maxHeight = system({
  maxHeight: {
    property: 'maxHeight',
    transform: px,
  },
});

const width = system({
  width: {
    property: 'width',
    transform: getWidth,
  },
});

const minWidth = system({
  minWidth: {
    property: 'minWidth',
    transform: px,
  },
});

const maxWidth = system({
  maxWidth: {
    property: 'maxWidth',
    transform: px,
  },
});

const position = system({
  position: true,
  zIndex: {
    property: 'zIndex',
    scale: 'zIndices',
  },
  top: {
    property: 'top',
    scale: 'space',
    transform: px,
  },
  right: {
    property: 'right',
    scale: 'space',
    transform: px,
  },
  bottom: {
    property: 'bottom',
    scale: 'space',
    transform: px,
  },
  left: {
    property: 'left',
    scale: 'space',
    transform: px,
  },
});

const layout = compose(
  width,
  height,
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
  overflow,
  display,
);

const flex = system({ flex: true });
const flexDirection = system({ flexDirection: true });
const flexWrap = system({ flexWrap: true });
const alignContent = system({ alignContent: true });
const alignItems = system({ alignItems: true });
const justifyContent = system({ justifyContent: true });
const justifyItems = system({ justifyItems: true });

export const styleMap = {
  alignContent,
  alignItems,
  color,
  display,
  flex,
  flexbox,
  flexDirection,
  flexWrap,
  fontSize,
  grid,
  height,
  justifyContent,
  justifyItems,
  layout,
  letterSpacing,
  lineHeight,
  maxHeight,
  maxWidth,
  minHeight,
  minWidth,
  overflow,
  position,
  space,
  textAlign,
  width,
  // Kosmos stuff below
  kosmosSpacing,
  kosmosBorderRadius,
  kosmosGrid,
  kosmosFontSize,
  kosmosLineHeight,
};
