import {
  blackAlpha700,
  blackAlpha900,
  blue100,
  blue50,
  blue500,
  blue600,
  blue700,
  blueDark500,
  gray100,
  gray200,
  gray300,
  gray400,
  gray500,
  gray600,
  green100,
  green50,
  green500,
  green600,
  green700,
  greenDark800,
  pinkDark50,
  pinkDark100,
  pinkDark600,
  pinkDark800,
  purple100,
  purple50,
  purpleDark500,
  purpleDark800,
  whiteBase,
  yellow100,
  yellow500,
  blue200,
  blue800,
  gray1100,
  gray1300,
  pinkDark200,
  purpleDark100,
  purpleLight200,
  yellowDark800,
  yellowLight300,
  purpleLightAlpha500,
  purpleDark50,
  yellowLight50,
  pinkDark700,
  blackBase,
  neutralAlpha700,
  neutralAlpha900,
  purpleDark700,
  purpleDark600,
  purple200,
  purple300,
  blackAlpha300,
  gray1400,
} from './palette';
import type { Colors } from './colors.types';

/** IMPORTANT! Any changes to colors needs to be reflected in tailwind!
 * 1. Apply the same changes to the respective theme in kosmosTokensToPaletteMap.ts, in playground-web
 * 2. Run localhost:1000/tailwind (playground-web) with the theme settings and use "console.log(colorTokenMap)"
 * 3. Update the light theme class framework colors in globals.css
 */

export const colors: Colors = {
  bg: whiteBase,
  bgStrong: gray300,
  bgOverlay: blackAlpha700,
  surface: whiteBase,
  surfaceHover: gray100,
  surfaceActive: gray200,
  surfaceChecked: purple50,
  surfaceCheckedHover: purpleLight200,
  surfaceRaised: whiteBase,
  surfaceSunken: gray100,
  surfaceOverlay: whiteBase,
  surfaceOverlayTransparent: neutralAlpha700,
  surfaceOverlayTransparentHover: neutralAlpha900,
  highContrast: blackBase,
  border: gray500,
  borderWeak: gray300,
  borderWeaker: gray100,
  borderStrong: gray600,
  borderBrandWeakest: blue100,
  borderPrimaryWeakest: gray200,
  borderNegativeWeakest: pinkDark100,
  borderNeutralWeakest: gray300,
  borderInfoWeakest: purple100,
  borderPositiveWeakest: green100,
  borderNoticeWeakest: yellow100,
  borderSecondaryWeakest: purpleDark100,
  borderDisabled: gray500,
  borderTransparent: blackAlpha300,
  brand: blue500,
  brandWeak: blue200,
  brandWeakest: blue50,
  text: gray1300,
  textLink: gray1100,
  textWeak: blackAlpha900,
  textWeaker: blackAlpha700,
  textNegative: pinkDark600,
  textPrimary: gray1100,
  textDisabled: gray500,
  onBrand: whiteBase,
  onBrandWeak: blue800,
  onBrandWeakest: blue800,
  onDisabled: gray500,
  onDisabledStrong: gray600,
  onInfo: whiteBase,
  onInfoWeak: purpleDark800,
  onInfoWeakest: purpleDark800,
  onInteractive: whiteBase,
  onHighContrast: gray100,
  onNeutral: gray1100,
  onNeutralWeak: gray1100,
  onNeutralWeakest: gray1100,
  onNegative: whiteBase,
  onNegativeWeak: pinkDark800,
  onNegativeWeakest: pinkDark800,
  onNotice: blueDark500,
  onNoticeWeak: yellowDark800,
  onNoticeWeakest: yellowDark800,
  onPositive: blueDark500,
  onPositiveWeak: greenDark800,
  onPositiveWeakest: greenDark800,
  onPrimary: whiteBase,
  onPrimaryWeak: gray1100,
  onPrimaryWeakest: gray1100,
  onSecondary: whiteBase,
  onSecondaryWeak: purpleDark800,
  onSecondaryWeakest: purpleDark800,
  primary: gray1100,
  primaryWeak: gray500,
  primaryWeakest: gray100,
  info: purpleDark500,
  infoWeak: purpleLight200,
  infoWeakest: purpleDark50,
  neutral: gray500,
  neutralWeak: gray200,
  neutralWeakest: gray200,
  negative: pinkDark600,
  negativeWeak: pinkDark100,
  negativeWeakest: pinkDark50,
  positive: green500,
  positiveWeak: green100,
  positiveWeakest: green50,
  secondary: purpleDark500,
  secondaryWeak: purpleLight200,
  secondaryWeakest: purple50,
  notice: yellow500,
  noticeWeak: yellowLight300,
  noticeWeakest: yellowLight50,
  disabled: gray100,
  disabledStrong: gray200,
  interactiveFocused: purpleLightAlpha500,
  // COMPONENT TOKENS
  buttonBgBrand: blue500,
  buttonBgBrandHover: blue600,
  buttonBgBrandActive: blue700,

  buttonBgNegative: pinkDark600,
  buttonBgNegativeHover: pinkDark700,
  buttonBgNegativeActive: pinkDark800,
  buttonBgNegativeWeak: pinkDark50,
  buttonBgNegativeWeakHover: pinkDark100,
  buttonBgNegativeWeakActive: pinkDark200,

  buttonBgPrimary: gray1100,
  buttonBgPrimaryHover: gray1300,
  buttonBgPrimaryActive: gray1400,
  buttonBgPrimaryChecked: purpleDark500,
  buttonBgPrimaryCheckedHover: purpleDark600,
  buttonBgPrimaryCheckedActive: purpleDark700,

  buttonBgSecondaryHover: gray200,
  buttonBgSecondaryActive: gray300,
  buttonBgSecondaryChecked: purple50,
  buttonBgSecondaryCheckedHover: purple100,
  buttonBgSecondaryCheckedActive: purple200,

  buttonBgTertiary: gray200,
  buttonBgTertiaryHover: gray400,
  buttonBgTertiaryActive: gray600,
  buttonBgTertiaryChecked: purpleDark500,
  buttonBgTertiaryCheckedHover: purpleDark600,
  buttonBgTertiaryCheckedActive: purpleDark700,

  buttonBgPrimaryReverse: whiteBase,
  buttonBgPrimaryReverseHover: gray400,
  buttonBgPrimaryReverseActive: gray600,
  buttonBgPrimaryReverseChecked: purpleDark500,
  buttonBgPrimaryReverseCheckedHover: purpleDark600,
  buttonBgPrimaryReverseCheckedActive: purpleDark700,

  buttonBgOutlineHover: gray100,
  buttonBgOutlineActive: gray200,
  buttonBgOutlineChecked: purpleLight200,
  buttonBgOutlineCheckedHover: purple200,
  buttonBgOutlineCheckedActive: purple300,

  buttonBgPositive: green500,
  buttonBgPositiveHover: green600,
  buttonBgPositiveActive: green700,
  inputBg: gray100,
  inputBgActive: whiteBase,
};
