import { compose } from '@styled-system/core';
import { margin } from './margin';
import { padding } from './padding';
import { grid } from './grid';
import { borderRadius } from './borderRadius';
import { fontSize } from './fontSize';
import { lineHeight } from './lineHeight';

const kosmosSpacing = compose(margin, padding);
const kosmosBorderRadius = borderRadius;
const kosmosGrid = grid;
const kosmosFontSize = fontSize;
const kosmosLineHeight = lineHeight;

export {
  kosmosSpacing,
  kosmosBorderRadius,
  kosmosGrid,
  kosmosFontSize,
  kosmosLineHeight,
};
