import { type Scale, get } from '@styled-system/core';
import { px } from '../utilites';
import { getThemeFromPropsArray, isKosmosToken, getTokenValue } from '../utils';

export function getBorderRadius(
  n: string,
  scale?: Scale,
  ...props: any
): string | undefined {
  if (n === '' || !scale) return;

  if (!isKosmosToken(n, scale)) {
    const theme = getThemeFromPropsArray(props);
    return px(get(theme.radii, n, n));
  }
  return px(getTokenValue(scale, n));
}
