import { typographyScale } from '../typography-scale';

/**
 * Code ownership: Slide & Live
 */

export interface VotingTypography {
  votingHeadline1: string;
  votingHeadline2: string;
  votingHeadline3: string;
  votingHeadline4: string;
  votingHeadline5: string;
  votingBodyMd: string;
  votingBodySm: string;
  votingLabelMd: string;
  votingLabelSm: string;
  votingButtonLg: string;
  votingNumberMd: string;
}

export const votingTypography: VotingTypography = {
  votingHeadline1: typographyScale.headlineLg,
  votingHeadline2: typographyScale.headlineMd,
  votingHeadline3: typographyScale.headlineSm,
  votingHeadline4: 'text-112.5 leading-normal font-semibold font-heading',
  votingHeadline5: typographyScale.headline2Xs,
  votingBodyMd: typographyScale.bodyMd,
  votingBodySm: typographyScale.bodySm,
  votingLabelMd: typographyScale.labelMd,
  votingLabelSm: typographyScale.labelSm,
  votingButtonLg: typographyScale.buttonLg,
  votingNumberMd: typographyScale.numberMd,
};
