import { type Scale } from '@styled-system/core';
import { px } from '../utilites';
import { getThemeFromPropsArray, isKosmosToken, getTokenValue } from '../utils';
import { getPadding as getRagnarPadding } from '../../';

export function getPadding(
  n: string,
  scale?: Scale,
  ...props: any
): string | undefined {
  if (n === '' || !scale) return;

  if (!isKosmosToken(n, scale)) {
    const theme = getThemeFromPropsArray(props);
    return getRagnarPadding(n, theme.space);
  }

  return px(getTokenValue(scale, n));
}
