import React from 'react';
import type { FontsEnum, FontWeightsEnum } from '@mentimeter/ragnar-dsc';
import { styled } from '@mentimeter/ragnar-styled';
import type {
  Rule,
  FontStyleProperty,
  TextTransformProperty,
  WordWrapProperty,
  WhiteSpaceProperty,
} from '@mentimeter/ragnar-styled';
import { cn } from '@mentimeter/ragnar-tailwind-config';
import { type Typography } from '@mentimeter/ragnar-typography';
import { text } from './Text.variants';

export const styledTextRule: Rule<{
  fontFamily?: FontsEnum;
  fontWeight?: FontWeightsEnum | undefined;
  fontStyle?: FontStyleProperty;
  textTransform?: TextTransformProperty;
  wordWrap?: WordWrapProperty;
  whiteSpace?: WhiteSpaceProperty;
  truncate?: boolean;
  ref?: React.ElementRef<any>;
  variant?: keyof Typography;
}> = ({
  theme,
  fontFamily,
  fontStyle,
  fontWeight,
  textTransform,
  wordWrap,
}) => ({
  fontFamily: fontFamily !== undefined ? theme.fonts[fontFamily] : fontFamily,
  fontWeight:
    fontWeight !== undefined ? theme.fontWeights[fontWeight] : fontWeight,
  fontStyle,
  textTransform,
  wordWrap,
});

const FelaText = styled('span')(
  styledTextRule,
  'color',
  'letterSpacing',
  'textAlign',
  'kosmosLineHeight',
  'kosmosSpacing',
  'kosmosFontSize',
  'maxWidth',
  'width',
  'display',
  'overflow',
  'flexbox',
  'position',
);

export type TextT = React.ComponentProps<typeof FelaText>;

export const Text = React.forwardRef((props: TextT, ref) => {
  const { truncate, className, variant, ...rest } = props;

  return (
    <FelaText
      {...rest}
      ref={ref}
      className={cn(text({ variant, truncate: Boolean(truncate) }), className)}
    />
  );
});
