import type {
  Colors,
  VisualizationColors,
  ColorsEnum,
} from '@mentimeter/ragnar-colors';
import { visualizationColors, colors } from '@mentimeter/ragnar-colors';

export interface Fonts {
  inherit: 'inherit';
  body: string;
  heading: string;
  monospace: string;
}

export interface LineHeights {
  none: number;
  body: number;
  heading: number;
}

export interface FontWeights {
  light: string | number;
  regular: string | number;
  semiBold: string | number;
  bold: string | number;
  extraBold: string | number;
}

export interface LetterSpacings {
  body: string | number;
}

export interface Opacity {
  light: number;
  disabled: number;
  faded: number;
}

export interface BoxShadow {
  subtle: string;
  close: string;
  far: string;
}

export interface zIndex {
  modal: number;
  notification: number;
  drawer: number;
  sticky: number;
}

export type KosmosSpacing =
  | 'space0'
  | 'space0.5'
  | 'space1'
  | 'space1.5'
  | 'space2'
  | 'space3'
  | 'space4'
  | 'space5'
  | 'space6'
  | 'space8'
  | 'space10'
  | 'space12'
  | 'space14'
  | 'space16'
  | 'space18'
  | 'space20';

export type KosmosNegativeSpacing =
  | '-space0.5'
  | '-space1'
  | '-space1.5'
  | '-space2'
  | '-space3'
  | '-space4'
  | '-space5'
  | '-space6'
  | '-space8'
  | '-space10'
  | '-space12'
  | '-space14'
  | '-space16'
  | '-space18'
  | '-space20';

export type KosmosBorderRadius =
  | 'none'
  | 'default'
  | 'lg'
  | 'xl'
  | '2xl'
  | '3xl'
  | '4xl'
  | 'full';

export type KosmosFontSize =
  | '62.5'
  | '75'
  | '87.5'
  | '100'
  | '112.5'
  | '125'
  | '150'
  | '175'
  | '200'
  | '225'
  | '250'
  | '300'
  | '400'
  | '500'
  | '600';

export type KosmosFixedLineHeight =
  | '75'
  | '100'
  | '125'
  | '150'
  | '175'
  | '200'
  | '225'
  | '250';

export type KosmosRelativeLineHeight =
  | 'none'
  | 'tight'
  | 'snug'
  | 'normal'
  | 'relaxed';

export interface DSC<C = Colors, VC = VisualizationColors, F = Fonts> {
  backdropAlpha?: number;
  colors: C;
  visualizationColors: VC;
  pictogramColor?: string | undefined;
  fonts: F;
  fontSizes: Array<number> | Array<string>;
  fontWeights: FontWeights;
  lineHeights: LineHeights;
  breakpoints: Array<string>;
  /**
   * @deprecated heights is deprecated and will be removed in the next major version.
   */
  heights: Array<number> | Array<string>;
  letterSpacings: LetterSpacings;
  opacity: Opacity;
  boxShadow: BoxShadow;
  borders: [number, number, ...number[]];
  space: [number, number, number, number, number, ...number[]];
  radii: Array<number>;
  durations: Array<number>;
  zIndex: zIndex;
  kosmosSpacing: { [key in KosmosSpacing]: number };
  kosmosBorderRadius: { [key in KosmosBorderRadius]: number };
  kosmosFontSize: { [key in KosmosFontSize]: number | string };
  kosmosLineHeight: {
    fixed: { [key in KosmosFixedLineHeight]: number | string };
    relative: { [key in KosmosRelativeLineHeight]: number };
  };
  kosmosBlur: {
    xl: string;
  };
}

export type FontsEnum = keyof Fonts;
export type LineHeightsEnum = keyof LineHeights | 'inherit';
export type FontWeightsEnum = keyof FontWeights;
export type LetterSpacingsEnum = keyof LetterSpacings;
export type Size = number | string;
export type Height = Size;
export type Width = Size;
export type Space = number | (string & {});
export type FontSize = number | (string & {}) | null;
export type BorderRadius = number | (string & {});
export type Border = number | string;
export type BoxShadowEnum = keyof BoxShadow;

const designSystemConfig: DSC = {
  colors,
  visualizationColors,
  breakpoints: ['600px', '960px', '1200px', '1800px'],
  space: [0, 4, 8, 16, 32, 64, 128],
  heights: [32, 40, 48, 56, 64],
  fontSizes: [10, 14, 16, 20, 24, 28, 36, 42, 48, 64],
  fonts: {
    inherit: 'inherit',
    body: 'MentiText, Arial, sans-serif',
    heading: 'MentiDisplay, Arial, sans-serif',
    monospace: 'monospace',
  },
  lineHeights: {
    none: 1,
    body: 1.4,
    heading: 1.2,
  },
  fontWeights: {
    light: 300,
    regular: 400,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  opacity: {
    light: 0.2,
    disabled: 0.4,
    faded: 0.6,
  },
  borders: [0, 2, 2], // There are 3 values in the design configuration for the Editor. So an extra "2" was added for backwards compatibility.
  letterSpacings: {
    body: 1.1,
  },
  zIndex: {
    notification: 9999,
    modal: 9998,
    drawer: 9997,
    sticky: 9996,
  },
  radii: [0, 4, 8, 9999],
  durations: [0.1, 0.3, 0.75],
  boxShadow: {
    subtle: '0 1px 1px',
    close: '0 2px 6px',
    far: '0 12px 20px',
  },
  kosmosBorderRadius: {
    none: 0,
    default: 4,
    lg: 8,
    xl: 12,
    '2xl': 16,
    '3xl': 24,
    '4xl': 32,
    full: 9999,
  },
  kosmosSpacing: {
    space0: 0,
    'space0.5': 2,
    space1: 4,
    'space1.5': 6,
    space2: 8,
    space3: 12,
    space4: 16,
    space5: 20,
    space6: 24,
    space8: 32,
    space10: 40,
    space12: 48,
    space14: 56,
    space16: 64,
    space18: 72,
    space20: 80,
  },
  kosmosFontSize: {
    62.5: 10,
    75: 12,
    87.5: 14,
    100: 16,
    112.5: 18,
    125: 20,
    150: 24,
    175: 28,
    200: 32,
    225: 36,
    250: 40,
    300: 48,
    400: 64,
    500: 80,
    600: 96,
  },
  kosmosLineHeight: {
    fixed: {
      75: 12,
      100: 16,
      125: 20,
      150: 24,
      175: 28,
      200: 32,
      225: 36,
      250: 40,
    },
    relative: {
      none: 1,
      tight: 1.1,
      snug: 1.2,
      normal: 1.4,
      relaxed: 1.625,
    },
  },
  kosmosBlur: {
    xl: 'blur(24px)',
  },
};

export default designSystemConfig;

/* type refinement function */
export const isThemeColor = (theme: DSC, c: ColorsEnum): c is keyof Colors =>
  Object.keys(theme.colors).includes(c);
