import { MissingStorage, Storage } from './storage';

const initLocalStorage = () => {
  try {
    return Storage(localStorage);
  } catch (_ex) {
    return Storage(MissingStorage('localStorage'));
  }
};

export const LocalStorage = initLocalStorage();
