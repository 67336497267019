/**
 * Code ownership: Ragnar
 */

export interface TypographyScale {
  headline6Xl: string;
  headline5Xl: string;
  headline4Xl: string;
  headline3Xl: string;
  headline2Xl: string;
  headlineXl: string;
  headlineLg: string;
  headlineMd: string;
  headlineSm: string;
  headlineXs: string;
  headline2Xs: string;
  bodyLg: string;
  bodyMd: string;
  bodySm: string;
  bodyXs: string;
  labelMd: string;
  labelSm: string;
  labelXs: string;
  numberLg: string;
  numberMd: string;
  numberSm: string;
  buttonLg: string;
  buttonMd: string;
  inputMd: string;
  inputSm: string;
}

export const typographyScale: TypographyScale = {
  headline6Xl: 'text-600 leading-none font-normal font-heading',
  headline5Xl: 'text-500 leading-none font-normal font-heading',
  headline4Xl: 'text-400 leading-tight font-normal font-heading',
  headline3Xl: 'text-300 leading-tight font-normal font-heading',
  headline2Xl: 'text-225 leading-tight font-normal font-heading',
  headlineXl: 'text-200 leading-tight font-normal font-heading',
  headlineLg: 'text-175 leading-tight font-normal font-heading',
  headlineMd: 'text-150 leading-tight font-normal font-heading',
  headlineSm: 'text-125 leading-150 font-normal font-heading',
  headlineXs: 'text-112.5 leading-normal font-normal font-heading',
  headline2Xs: 'text-100 leading-normal font-semibold font-body',

  bodyLg: 'text-125 leading-normal font-normal font-body',
  bodyMd: 'text-100 leading-normal font-normal font-body',
  bodySm: 'text-87.5 leading-normal font-normal font-body',
  bodyXs: 'text-75 leading-normal font-normal font-body',

  labelMd: 'text-100 leading-normal font-normal font-body',
  labelSm: 'text-87.5 leading-normal font-semibold font-body',
  labelXs: 'text-62.5 leading-normal font-semibold font-body',

  numberLg: 'text-112.5 leading-relaxed font-normal font-body',
  numberMd: 'text-87.5 leading-relaxed font-normal font-body',
  numberSm: 'text-75 leading-relaxed font-normal font-body',

  buttonLg: 'text-100 leading-125 font-semibold font-body',
  buttonMd: 'text-87.5 leading-100 font-semibold font-body',

  inputMd: 'text-100 leading-tight font-normal font-body',
  inputSm: 'text-87.5 leading-tight font-normal font-body',
};
