import Color from 'color';

export function alpha(color: string, alphaValue: number): string {
  return Color(color).alpha(alphaValue).string();
}

export function shade(color: string, mix: string, amount: number): string {
  return Color(color).mix(Color(mix), amount).string();
}

export function contrast(color1: string, color2: string): number {
  return Color(color1).contrast(Color(color2));
}

export function contrastColor(color: string): string {
  return Color(color).luminosity() > 0.5 ? '#000000' : '#ffffff';
}

export function luminosity(color: string): number {
  return Color(color).luminosity();
}

export function isLight(color: string): boolean {
  return Color(color).isLight();
}

/**
 * @deprecated Use createAccessibleColor instead
 */
export function accessibleShade(
  color: string,
  mix: string,
  amount: number,
  increment: number,
): string {
  let output = shade(color, mix, amount);
  // If the first try was not constasting enough, add more shade
  if (contrast(output, color) < 1.1) {
    output = shade(color, mix, amount + increment);
  }
  return output;
}

/**
 * Creates a color that contrasts with the bgColor and is accessible
 * according to WCAG 2.0 AA by default.
 * @param {string} bgColor - The background color to make sure the new color is contrasting against
 * @param {string} baseColor - The base color to work from
 * @param {string} mixColor - The color to mix with the baseColor, usually a light or dark color
 * @param {number} amountOfMixColor - The amount of mixColor to mix with the baseColor, a value from 0-1
 * @param {number} contrastLimit - The contrast limit to reach, defaults to 4.5
 * @returns {string} - The newly mixed color
 */
interface CreateAccessibleColorI {
  bgColor: string;
  baseColor: string;
  mixColor: string;
  amountOfMixColor: number;
  contrastLimit?: number;
}

export function createAccessibleColor({
  bgColor,
  baseColor,
  mixColor,
  amountOfMixColor,
  contrastLimit = 4.5,
}: CreateAccessibleColorI): string {
  const incrementValue = 0.01;

  // Add amountOfMixColor, from 0-1, of mixColor to baseColor
  let output = shade(baseColor, mixColor, amountOfMixColor);
  let increment = incrementValue;
  // Check if the new color is contrasting enough agianst the bgColor and if not,
  // add more of mixColor in incremental steps of 0.05
  while (contrast(output, bgColor) < contrastLimit) {
    output = shade(baseColor, mixColor, amountOfMixColor + increment);
    increment += incrementValue;
    // when 100% of mixColor is added and it still didn't contrast enough
    // return the initial baseColor. When the bgColor and baseColor are the same, return the mixColor
    if (increment > 1.0) {
      if (baseColor === bgColor) return mixColor;
      return baseColor;
    }
  }

  // When AA contrast is reached, return the newly mixed color
  return output;
}

export function toHex(color: string, bg: string): string {
  const c = Color(color);
  if (c.alpha() !== 1) {
    return c.mix(Color(bg), 1 - c.alpha()).hex();
  }
  return c.hex();
}

interface RgbObject {
  r: number;
  g: number;
  b: number;
  alpha?: number;
}

export function toRgb(color: string): RgbObject {
  const c = Color(color);
  return c.rgb().object() as any;
}

export function toRgbString(color: string) {
  return Color(color).rgb().string();
}

export function toSolidRgbString(color: string) {
  return Color(Color(color).alpha(1)).rgb().string();
}

export function lighten(color: string, amount: number): string {
  return Color(Color(color).lighten(amount)).hex();
}

export function darken(color: string, amount: number): string {
  return Color(Color(color).darken(amount)).hex();
}
