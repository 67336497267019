'use client';
import { useEffect, useState } from 'react';
import { useParams } from 'next/navigation';
import { DEFAULT_REGION, identifierKeysMap } from '@mentimeter/identifier';
import Cookies from '@mentimeter/cookies';
import { getRegionByVoteKey } from '@mentimeter/region';
import { usePrevious } from '@mentimeter/react-hooks';
import type { Regions } from '@mentimeter/http-clients';
import { getIdentifier } from './identifier';

export function useIdentifier(useDefaultRegion = false) {
  const region = useRegion(useDefaultRegion);
  const [identifier, setIdentifier] = useState<string | undefined>(() =>
    getIdentifierFromCookie(region),
  );
  useClientIdentifierFallback(identifier, setIdentifier);
  useUpdateOnRegionChange(region, setIdentifier);

  return identifier;
}

function useRegion(useDefaultRegion: boolean) {
  const voteParams = useParams()?.voteParams;
  const voteKey = Array.isArray(voteParams) ? voteParams[0] : undefined;
  return voteKey && !useDefaultRegion
    ? getRegionByVoteKey(voteKey)
    : DEFAULT_REGION;
}

function useClientIdentifierFallback(
  identifier: string | undefined,
  setIdentifier: (identifier: string) => void,
) {
  useEffect(() => {
    if (identifier) return;
    getIdentifier().then((storedOrNewIdentifier) => {
      if (storedOrNewIdentifier) {
        setIdentifier(storedOrNewIdentifier);
      }
    });
  }, [identifier, setIdentifier]);
}

function getIdentifierFromCookie(region: Regions) {
  return Cookies.get(identifierKeysMap.raw[region]);
}

function useUpdateOnRegionChange(
  region: Regions,
  setIdentifier: (identifier: string) => void,
) {
  const prevRegion = usePrevious(region);

  useEffect(() => {
    if (region === prevRegion) return;
    const newIdentifier = getIdentifierFromCookie(region);
    if (newIdentifier) {
      setIdentifier(newIdentifier);
    }
  }, [region, prevRegion, setIdentifier]);
}
