import { addLinkupHeaders } from '@mentimeter/tracing';
import { DEFAULT_REGION, type Region } from './map';

const FETCH_TIMEOUT = 25000;

export const getIdentifierUrl = (region: Region) =>
  region === DEFAULT_REGION ? `/core/identifiers` : `/us/core/identifiers`;

export interface IdentifierResponse {
  identifier: string | null;
  jwt: string | null;
  region: Region;
}

export const fetcher = async (
  host: string,
  region: Region,
  requestHeaders: Headers,
  identifier: string | null = '',
  tries = 0,
): Promise<IdentifierResponse> => {
  const method = identifier ? 'GET' : 'POST';
  const url = `${host}${getIdentifierUrl(region)}${
    identifier ? '/' + identifier : ''
  }`;

  const forwardHeaders = new Headers();
  addLinkupHeaders(forwardHeaders, requestHeaders);

  const options: RequestInit = {
    headers: forwardHeaders,
    method,
    signal: abortSignalTimeout(FETCH_TIMEOUT),
  };

  let response;
  try {
    response = await fetch(url, options);
  } catch (error) {
    // When fetch fails, it is most commonly due to a network error
    // or blocked network call that we can't recover from
    return { identifier: null, jwt: null, region };
  }
  if (response.ok) {
    return {
      ...(await response.json()),
      region,
    };
  }
  // 404 means that the identifier was not found and a
  // new one has to be created, retry max 3 times.
  if (response.status === 404 && tries <= 3) {
    return fetcher(host, region, requestHeaders, null, tries + 1);
  }
  if (response.status >= 400) {
    return { identifier: null, jwt: null, region };
  }
  throw new Error(`Fetching identifier failed: ${response.status}`);
};

function abortSignalTimeout(timeout: number) {
  const controller = new AbortController();
  setTimeout(() => controller.abort(), timeout);
  return controller.signal;
}
