export const DEFAULT_REGION = 'eu';
export type Region = 'eu' | 'us';
type Regions = Region[];
export const regions: Regions = ['eu', 'us'];

// Raw keys
const IDENTIFIER_EU = 'identifier1';
const IDENTIFIER_US = 'blidentifier';

// JWT keys
const IDENTIFIER_JWT_EU = 'identifierjwt';
const IDENTIFIER_JWT_US = 'blidentifierjwt';

export type IdentifierTypes = 'raw' | 'jwt';

export const identifierKeysMap: Record<
  'raw' | 'jwt',
  Record<Region, string>
> = {
  raw: {
    eu: IDENTIFIER_EU,
    us: IDENTIFIER_US,
  },
  jwt: {
    eu: IDENTIFIER_JWT_EU,
    us: IDENTIFIER_JWT_US,
  },
};
