export function addLinkupHeaders(
  onwardFetchHeaders: Headers,
  incomingRequestHeaders: Headers,
) {
  const linkupHeadersToForward = ['tracestate', 'traceparent'];
  for (const header of linkupHeadersToForward) {
    if (incomingRequestHeaders.has(header)) {
      onwardFetchHeaders.set(header, incomingRequestHeaders.get(header)!);
    }
  }
}
