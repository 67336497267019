import { system } from '@styled-system/core';
import { getMargin } from './getMargin';

export const margin = system({
  m: {
    property: 'margin',
    scale: 'kosmosSpacing',
    transform: getMargin,
  },
  mt: {
    property: 'marginTop',
    scale: 'kosmosSpacing',
    transform: getMargin,
  },
  mr: {
    property: 'marginRight',
    scale: 'kosmosSpacing',
    transform: getMargin,
  },
  mb: {
    property: 'marginBottom',
    scale: 'kosmosSpacing',
    transform: getMargin,
  },
  ml: {
    property: 'marginLeft',
    scale: 'kosmosSpacing',
    transform: getMargin,
  },
  mx: {
    properties: ['marginLeft', 'marginRight'],
    scale: 'kosmosSpacing',
    transform: getMargin,
  },
  my: {
    properties: ['marginTop', 'marginBottom'],
    scale: 'kosmosSpacing',
    transform: getMargin,
  },
});
