import type { StorageType } from './types';

export interface StorageSetParams {
  type: StorageType;
  key: string;
  value: string;
}

export const MissingStorage = (storageTypeName: string): Storage => ({
  length: 0,
  key(nth: number): string | null {
    throw new Error(`Storage Type ${storageTypeName} not supported`);
  },
  getItem(key: string): string | null {
    throw new Error(`Storage Type ${storageTypeName} not supported`);
  },
  setItem(key: string, value: string): void {
    throw new Error(`Storage Type ${storageTypeName} not supported`);
  },
  removeItem(key: string): void {
    throw new Error(`Storage Type ${storageTypeName} not supported`);
  },
  clear(): void {
    throw new Error(`Storage Type ${storageTypeName} not supported`);
  },
});

export const Storage = (storage: Storage) => {
  // optimization: cached since it might be used in render.
  let cachedEnabled: boolean;

  return {
    isSupported() {
      if (cachedEnabled !== undefined) return cachedEnabled;

      // Some browsers (including chrome) will throw an error if client does not have access to storage
      // e.g. by testing if typeof storage === 'undefined'. That's why we wrap it in a try catch.
      try {
        storage.setItem('ls_test', '1234');
        const enabled = storage.getItem('ls_test') === '1234';
        storage.removeItem('ls_test');
        cachedEnabled = enabled;
      } catch (e) {
        cachedEnabled = false;
      }
      return cachedEnabled;
    },

    getItem(key: string): string | null {
      try {
        return storage.getItem(key);
      } catch (_ex) {
        return null;
      }
    },

    getJSONItem<T>(key: string): T | null {
      try {
        const value = storage.getItem(key);
        if (value === null) return null;
        return JSON.parse(value);
      } catch (_ex) {
        return null;
      }
    },

    removeItem(key: string): void {
      try {
        storage.removeItem(key);
      } catch (_ex) {}
    },

    clear() {
      try {
        storage.clear();
      } catch (_ex) {}
    },

    /**
     * Sets the value for a key in local storage
     *
     * @param { StorageSetParams } params
     * @param { StorageType } params.type -
     * - **necessary** used if they are necessary for the service to function. E.g. setting privacy preferences or logging in.
     * - **functional** used to enhance functionality and personalisation.
     * - **performance** used to measure performance.
     * - **targeting** used to build a profile or tailor user experience.
     * @param { string } params.key - storage key
     * @param { string } params.value - storage value
     */
    setItem({
      type, // "type" will be used later to identify what cookies we're allowed to set and not.
      key,
      value,
    }: StorageSetParams) {
      try {
        storage.setItem(key, value);
      } catch (_ex) {}
    },

    /**
     * Sets the value for a key in local storage
     *
     * @param { StorageSetParams } params
     * @param { StorageType } params.type -
     * - **necessary** used if they are necessary for the service to function. E.g. setting privacy preferences or logging in.
     * - **functional** used to enhance functionality and personalisation.
     * - **performance** used to measure performance.
     * - **targeting** used to build a profile or tailor user experience.
     * @param { string } params.key - storage key
     * @param { any } params.value - storage value
     */
    setJSONItem<T>({
      type,
      key,
      value,
    }: Omit<StorageSetParams, 'value'> & { value: T }) {
      this.setItem({ type, key, value: JSON.stringify(value) });
    },
  };
};
