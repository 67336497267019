import { system } from '@styled-system/core';
import { getLineHeight } from './getLineHeight';

export const lineHeight = system({
  lineHeight: {
    property: 'lineHeight',
    scale: 'kosmosLineHeight',
    transform: getLineHeight,
  },
});
