import {
  type ContentTypography,
  contentTypography,
} from '../content-typography';
import {
  type DashboardTypography,
  dashboardTypography,
} from '../dashboard-typography';
import { type EditorTypography, editorTypography } from '../editor-typography';
import {
  type PresentationTypography,
  presentationTypography,
} from '../presentation-typography';
import { type RagnarTypography, ragnarTypography } from '../ragnar-typography';
import {
  type ResultsTypography,
  resultsTypography,
} from '../results-typography';
import { type VotingTypography, votingTypography } from '../voting-typography';

export type Typography = ContentTypography &
  DashboardTypography &
  EditorTypography &
  PresentationTypography &
  RagnarTypography &
  ResultsTypography &
  VotingTypography;

export const typography: Typography = {
  ...contentTypography,
  ...dashboardTypography,
  ...editorTypography,
  ...presentationTypography,
  ...ragnarTypography,
  ...resultsTypography,
  ...votingTypography,
};
