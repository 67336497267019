/**
 * Key and Value of the tag can include alphanumerics, underscores, minuses, colons, periods, slashes, no spaces
 *
 * Follow the link for more details: https://docs.datadoghq.com/getting_started/tagging/#define-tags
 */
type Tag = `${string}:${string}`;

/**
 * Custom Metric to send to datadog
 *
 * @param {string} name: It is the name of the metrics. That will show in datadog.
 * @param {number} value: The numeric value of the metric. Default value is 1.
 * @param tag: Used to filter the metrics.
 *
 */

export interface Metric {
  name: `mmjs.${string}` | `ably.${string}`;
  value?: number;
  tags?: Tag[];
}

interface DatadogMetrics {
  series: [
    {
      metric: string;
      type: number;
      points: [
        {
          timestamp: number;
          value: number;
        },
      ];
      tags: string[] | undefined;
    },
  ];
}

interface DatadogDistribution {
  series: [
    {
      metric: string;
      points: [[number, number[]]];
      tags: string[] | undefined;
    },
  ];
}

export function createDatadogMetricObject(metric: Metric): DatadogMetrics {
  metric.value ??= 1;

  return {
    series: [
      {
        metric: metric.name,
        type: 1,
        points: [
          {
            timestamp: Math.round(new Date().getTime() / 1000),
            value: metric.value,
          },
        ],
        tags: metric.tags,
      },
    ],
  };
}

export function createDatadogDistributionObject(
  metric: Metric,
): DatadogDistribution {
  metric.value ??= 1;

  return {
    series: [
      {
        metric: metric.name,
        points: [[Math.round(new Date().getTime() / 1000), [metric.value]]],
        tags: metric.tags,
      },
    ],
  };
}

export function sendMetric(metric: Metric) {
  const datadogCustomMetric = createDatadogMetricObject(metric);

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  navigator?.sendBeacon &&
    navigator.sendBeacon(
      '/client-metrics',
      JSON.stringify(datadogCustomMetric),
    );
}

export function sendDistribution(metric: Metric) {
  const datadogCustomMetric = createDatadogDistributionObject(metric);

  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  navigator?.sendBeacon &&
    navigator.sendBeacon(
      '/client-distributions',
      JSON.stringify(datadogCustomMetric),
    );
}
