import Cookies from 'js-cookie';
import type { CookieType } from './types';

export const get = Cookies.get;

export const getJSON = <T>(name: string): T | undefined => {
  try {
    const value = Cookies.get(name);
    return value !== undefined ? JSON.parse(value) : undefined;
  } catch (_ex) {}
  return undefined;
};

export const remove: typeof Cookies.remove = Cookies.remove;

interface CookieSetParams<T = string> {
  type: CookieType;
  name: string;
  value: T;
  options?: Cookies.CookieAttributes;
}

/**
 * Creates a cookie
 *
 * @param { CookieSetParams } params
 * @param { CookieType } params.type -
 * - **necessary** used if they are necessary for the service to function. E.g. setting privacy preferences or logging in.
 * - **functional** used to enhance functionality and personalisation.
 * - **performance** used to measure performance.
 * - **targeting** used to build a profile or tailor user experience.
 * @param { string } params.name - cookie name
 * @param { string } params.value - cookie value
 * @param { Cookies.CookieAttributes } [params.options] - secure attribute will default to true if nothing else is set.
 * @returns undefined or a string with the full cookie if set (e.g. name=value).
 */
export const set: (params: CookieSetParams) => string | undefined = ({
  type, // "type" will be used later to identify what cookies we're allowed to set and not.
  name,
  value,
  options = {},
}) => Cookies.set(name, value, { secure: true, ...options });

export const setJSON: <T>(params: CookieSetParams<T>) => string | undefined = ({
  value,
  ...otherParams
}) => set({ ...otherParams, value: JSON.stringify(value) });

export const cookiesAreEnabled = () => {
  if (typeof window === 'undefined') return false;
  return Boolean(navigator?.cookieEnabled);
};
