import type { HTMLAttributes } from 'react';
import React from 'react';
import hoistNonReactStatics from 'hoist-non-react-statics';
import { compose, styleMap } from '@mentimeter/ragnar-system';
import type { As, Extend } from '@mentimeter/ragnar-dsc';
import type { TRule } from 'fela';
import { designSystemConfig } from '@mentimeter/ragnar-dsc';
import {
  combineRules,
  RendererContext,
  ThemeContext,
} from '@mentimeter/ragnar-react';
import { filterValidProps } from './isPropValid';
import warn from './utils/warn';
import type { RagnarStyleProperties } from './types/style-properties';

function isRagnarComponent(Comp: any): Comp is RagnarComponent {
  return typeof Comp !== 'string' && Comp['isRagnarComponent'] === true;
}

function isSystemComponent(Comp: any): Comp is RagnarComponent {
  return typeof Comp !== 'string' && Comp['isSystemComponent'] === true;
}

function isRegularComponent(Comp: any): Comp is React.ComponentType {
  return typeof Comp !== 'string' && !Comp['isRagnarComponent'];
}

interface OptionsT {
  as?: As;
  displayName?: string;
}

export interface ExternalRagnarComponentProps {
  // false-positive
  extend?: Extend | undefined;
  // false-positive
  as?: As;
}

interface InternalRagnarComponentProps extends ExternalRagnarComponentProps {
  _ragnarRule?: TRule;
  _ragnarRef?: React.ForwardedRef<unknown>;
  forwardedRef?: React.ForwardedRef<unknown>;
}

type RagnarComponent<T = Record<string, any>> = React.ComponentType<T> & {
  isRagnarComponent: true;
  isSystemComponent: boolean;
};

/**
 * [React 19]
 * In React 19, `keyof React.ReactHTML` will be removed.
 * A partial alternative _might_ be `React.HTMLElementType`.
 */
type ComponentT = React.ComponentType<any> | keyof React.ReactHTML;

function styled<T extends ComponentT>(
  Comp: T,
  options?: OptionsT,
  filterProps?: boolean,
  additionalValidProps?: string[],
) {
  return function <U, K extends keyof typeof styleMap>(
    maybeRule?: TRule<U> | K,
    ...system: K[]
  ) {
    // No custom rule was passed, so first arg is a system rule
    if (typeof maybeRule === 'string') system.push(maybeRule);

    const systemFns = system.map((sys) => styleMap[sys]);

    if (
      process.env['NODE_ENV'] === 'development' &&
      systemFns.length > 0 &&
      isSystemComponent(Comp)
    ) {
      warn(
        `It's not possible to use system props on a RagnarComponent already using system props.`,
      );
    }

    const systemRule = compose(...systemFns);

    const Styled = ({
      extend,
      children,
      as,
      forwardedRef,
      _ragnarRule,
      ...props
    }: InternalRagnarComponentProps & HTMLAttributes<unknown>) => {
      const renderer = React.useContext(RendererContext);
      const theme = React.useContext(ThemeContext) || designSystemConfig;
      const rules: TRule<any>[] =
        typeof maybeRule === 'function' ? [maybeRule] : [];

      if (_ragnarRule) rules.push(_ragnarRule);
      if (systemRule) rules.push(() => systemRule({ ...props, theme }));
      if (extend) rules.push(extend ?? {});

      const combinedRule = combineRules(...rules);

      if (isRagnarComponent(Comp))
        return React.createElement(
          Comp,
          {
            _ragnarRule: combinedRule,
            _ragnarRef: forwardedRef,
            as: as ?? options?.as,
            ...props,
          },
          children,
        );

      const { _ragnarRef, className: customClassName } = props;
      const renderedElement: As | typeof Comp = as ?? options?.as ?? Comp;

      const className = renderer.renderRule(combinedRule, {
        ...props,
        as: renderedElement,
        theme,
      });

      const validProps =
        isRegularComponent(Comp) && !filterProps
          ? props
          : filterValidProps(props, additionalValidProps);

      const cn = customClassName
        ? `${className} ${customClassName}`
        : className;

      return React.createElement(
        renderedElement,
        {
          ...validProps,
          className: cn,
          ref: forwardedRef || _ragnarRef,
        },
        children,
      );
    };

    Styled.isRagnarComponent = true;
    Styled.isSystemComponent = systemRule !== undefined;
    Styled.displayName = options?.displayName || 'Styled';

    const Ref = React.forwardRef<
      any,
      Omit<React.ComponentProps<typeof Comp>, keyof RagnarStyleProperties<K>> &
        Omit<U, 'theme'> &
        ExternalRagnarComponentProps &
        RagnarStyleProperties<K>
    >((props, ref) => {
      return React.createElement(Styled, {
        ...props,
        forwardedRef: ref,
      });
    });
    hoistNonReactStatics(Ref, Styled);

    return Ref;
  };
}

export default styled;
