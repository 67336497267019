import { system } from '@styled-system/core';
import { getFontSize } from './getFontSize';

export const fontSize = system({
  fontSize: {
    property: 'fontSize',
    scale: 'kosmosFontSize',
    transform: getFontSize,
  },
});
