import { typographyScale } from '../typography-scale';

/**
 * Code ownership: PUG, T-rex, Mango
 */

export interface DashboardTypography {
  dashboardHeadline1: string;
  dashboardHeadline2: string;
  dashboardHeadline3: string;
  dashboardHeadline4: string;
  dashboardHeadline5: string;
  dashboardBodyLg: string;
  dashboardBodyMd: string;
  dashboardBodySm: string;
  dashboardBodyXs: string;
  dashboardLabelMd: string;
  dashboardLabelSm: string;
  dashboardLabelXs: string;
  dashboardInputMd: string;
  dashboardInputSm: string;
}

export const dashboardTypography: DashboardTypography = {
  dashboardHeadline1: typographyScale.headline3Xl,
  dashboardHeadline2: typographyScale.headline2Xl,
  dashboardHeadline3: typographyScale.headlineLg,
  dashboardHeadline4: typographyScale.headlineSm,
  dashboardHeadline5: typographyScale.headline2Xs,
  dashboardBodyLg: typographyScale.bodyLg,
  dashboardBodyMd: typographyScale.bodyMd,
  dashboardBodySm: typographyScale.bodySm,
  dashboardBodyXs: typographyScale.bodyXs,
  dashboardLabelMd: typographyScale.labelMd,
  dashboardLabelSm: typographyScale.labelSm,
  dashboardLabelXs: typographyScale.labelXs,
  dashboardInputMd: typographyScale.inputMd,
  dashboardInputSm: typographyScale.inputSm,
};
