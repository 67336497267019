import { system } from '@styled-system/core';
import { getBorderRadius } from './getBorderRadius';

export const borderRadius = system({
  borderRadius: {
    property: 'borderRadius',
    scale: 'kosmosBorderRadius',
    transform: getBorderRadius,
  },
  borderTopRightRadius: {
    property: 'borderTopRightRadius',
    scale: 'kosmosBorderRadius',
    transform: getBorderRadius,
  },
  borderTopLeftRadius: {
    property: 'borderTopLeftRadius',
    scale: 'kosmosBorderRadius',
    transform: getBorderRadius,
  },
  borderBottomRightRadius: {
    property: 'borderBottomRightRadius',
    scale: 'kosmosBorderRadius',
    transform: getBorderRadius,
  },
  borderBottomLeftRadius: {
    property: 'borderBottomLeftRadius',
    scale: 'kosmosBorderRadius',
    transform: getBorderRadius,
  },
});
