import { type Scale, get } from '@styled-system/core';
import { px } from '../utilites';
import { getThemeFromPropsArray, isKosmosToken, getTokenValue } from '../utils';

const isInScale = (n: string, scale: Scale): boolean => {
  return Object.keys(scale).includes(n);
};

interface KosmosLineHeightScales {
  fixed: Scale;
  relative: Scale;
}

const getScale = (
  n: string,
  scale: KosmosLineHeightScales,
): [string, Scale] | [] => {
  if (isInScale(n, scale.fixed)) return ['fixed', scale.fixed];
  if (isInScale(n, scale.relative)) return ['relative', scale.relative];

  return [];
};

export const getLineHeight = (
  n: string,
  scale?: Scale,
  ...props: any
): number | string | undefined => {
  if (n === '' || !scale) return;

  const [name, currentScale] = getScale(
    n,
    scale as unknown as KosmosLineHeightScales,
  );

  if (!isKosmosToken(n, currentScale)) {
    const theme = getThemeFromPropsArray(props);
    return get(theme.lineHeights, n, n) ?? undefined;
  }

  const value = getTokenValue(currentScale, n);

  if (name === 'fixed') return px(value);
  return value;
};
