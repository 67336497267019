import { IdentifierClient } from '@mentimeter/identifier';
import { MentiError, captureException } from '@mentimeter/errors/sentry';
import { setUser as setSentryUser } from '@sentry/nextjs';
import { wasHandledNetworkError } from '@mentimeter/http-clients';

const canonicalHost = process.env.NEXT_PUBLIC_CANONICAL_HOST as string;

export const identifierClient = new IdentifierClient({
  canonicalUrl: canonicalHost,
  region: 'eu',
});

const handleIdentifierError = (error: Error) => {
  if (!wasHandledNetworkError(error)) {
    captureException(
      new MentiError(error.message || 'Unknown identifier error', {
        feature: 'api',
        cause: error,
      }),
    );
  }
};

const IDENTIFIER_FALLBACK = null;

export const getIdentifier = async (): Promise<string | null> => {
  try {
    const { identifier } = await identifierClient.getIdentifier();
    return identifier || IDENTIFIER_FALLBACK;
  } catch (error) {
    if (error instanceof Error) {
      handleIdentifierError(error);
    }
    return IDENTIFIER_FALLBACK;
  }
};

export const getIdentifierJwt = async (): Promise<string | null> => {
  try {
    const { jwt } = await identifierClient.getIdentifier();
    return jwt || IDENTIFIER_FALLBACK;
  } catch (error) {
    if (error instanceof Error) {
      handleIdentifierError(error);
    }
    return IDENTIFIER_FALLBACK;
  }
};

export const resetIdentifier = () => {
  setSentryUser(null);
  identifierClient.clearIdentifier();
};
