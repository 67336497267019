import { cva } from '@mentimeter/ragnar-tailwind-config';
import { typography } from '@mentimeter/ragnar-typography';

export const text = cva(['r-text', 'break-words', 'max-w-full', 'text-weak'], {
  variants: {
    variant: {
      ...typography,
    },
    truncate: {
      true: ['text-ellipsis', 'whitespace-nowrap', 'overflow-hidden', 'block'],
      false: '',
    },
  },
  defaultVariants: {
    variant: 'ragnarBodyMd',
  },
});
