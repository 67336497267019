import { typographyScale } from '../typography-scale';

/**
 * Code ownership: CX
 */

export interface EditorTypography {
  editorHeadline1: string;
  editorHeadline2: string;
  editorHeadline3: string;
  editorHeadline4: string;
  editorHeadline5: string;
  editorBodyLg: string;
  editorBodyMd: string;
  editorBodySm: string;
  editorBodyXs: string;
  editorLabelMd: string;
  editorLabelSm: string;
  editorLabelXs: string;
  editorButtonLg: string;
  editorButtonMd: string;
}

export const editorTypography: EditorTypography = {
  editorHeadline1: typographyScale.headlineLg,
  editorHeadline2: typographyScale.headlineMd,
  editorHeadline3: typographyScale.headlineSm,
  editorHeadline4: typographyScale.headlineXs,
  editorHeadline5: typographyScale.headline2Xs,
  editorBodyLg: typographyScale.bodyLg,
  editorBodyMd: typographyScale.bodyMd,
  editorBodySm: typographyScale.bodySm,
  editorBodyXs: typographyScale.bodyXs,
  editorLabelMd: typographyScale.labelMd,
  editorLabelSm: typographyScale.labelSm,
  editorLabelXs: typographyScale.labelXs,
  editorButtonLg: typographyScale.buttonLg,
  editorButtonMd: typographyScale.buttonMd,
};
