import {
  blue500,
  blueDark500,
  green500,
  pink500,
  pinkDark500,
  purple500,
  whiteBase,
  yellow500,
  yellowDark500,
} from './palette';

export const visualizationColors = {
  backgroundColor: whiteBase,
  textColor: blueDark500,
  lineColor: blueDark500,
  fillColors: [
    blue500,
    pink500,
    pinkDark500,
    yellow500,
    green500,
    purple500,
    yellowDark500,
  ],
  fillTextColors: [
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
    whiteBase,
  ],
};
